import * as React from 'react';
import Layout from '../components/layout';
import Seo from '../components/seo';
import Image from '../components/Image';

const NotFoundPage = () => (
  <Layout page="maintenance">
    <div className="inner">
      <Seo title="メンテナンス" />
      <section className="page-content">
        <div className="brand-logo-list">
          <Image className="full-image" filename="logo/andersen.png" alt="アンデルセンストーブ" />
          <div className="sub-brand-logo-list">
            <Image className="full-image" filename="logo/morso.png" alt="morso" />
            <Image className="full-image" filename="logo/leda.png" alt="leda" />
          </div>
        </div>
        <p className="futura title small mt-8">MAINTENANCE</p>
        <p className="mt-8 md:text-xl">
          ただいまメンテナンスのため一時サービスを停止しております。<br />
          大変ご不便をおかけいたしますが、今しばらくお待ちください。
        </p>
      </section>
    </div>
  </Layout>
);

export default NotFoundPage;
